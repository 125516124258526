<template>
     <SCPage :title="title">
        <div class="exception-body notfound">
            <div class="exception-wrapper">
                <div class="inpage-exception-content">
                    <span>Role Restricted</span>
                </div>
            </div>
        </div>
    </SCPage>
</template>

<script>
import { SCPage } from '@scale-computing/scale-design-framework';
export default {
    components: {
        SCPage
    },
    computed: {
        colorScheme() {
            return this.$appState.colorScheme;
        },
        title(){
            return this.$route.meta.breadcrumb ? this.$route.meta.breadcrumb.slice(-1)[0].label : 'Unauthorized'
        }
    },
};
</script>
