import api from './api-v2';

const filter = async (filters) => {
    const client = api.getClient();
    return await api.handleSuccess(await client.get('vms', { params: filters }));
};

const download = async (filters) => {
    const client = api.getClient();
    return await client.get('vms/download', { params: filters, responseType: 'stream' }); // create file link in browser's memory
};

export default {
    filter,
    download,
};
