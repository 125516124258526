<template>
    <SCMenu :items="helpItems" theme="dark" icon="help"/>
</template>

<script>
import { SCMenu } from '@scale-computing/scale-design-framework';
export default {
    name: 'AppHelp',
    components: { SCMenu },
    data() {
        return {
            helpItems: [
                {
                    label: 'Read User Guide',
                    url: 'https://scalecomputing.my.salesforce.com/sfc/p/700000008AKW/a/4u0000019yBV/BT9qsos01XIXnbP85uBMLHE5AbtdRBhhxxjxGl3OCIU',
                    target: '_blank',
                    command: (event) => this.helpClick(event),
                },
                {
                    label: 'Support Resources',
                    url: 'https://www.scalecomputing.com/support',
                    target: '_blank',
                    command: (event) => this.helpClick(event),
                },
                {
                    label: 'Chat with Support',
                    url: 'https://scalecomputing.com/support-chat',
                    target: '_blank',
                    command: (event) => this.helpClick(event),
                },
                {
                    label: 'Privacy Policy',
                    url: 'https://www.scalecomputing.com/privacy-policy',
                    target: '_blank',
                    command: (event) => this.helpClick(event),
                },
            ],
        };
    },
    methods: {
        helpClick(event) {
            this.$gtag.event('help_click', { event_category: 'Help', event_label: event.item.label });
        },
    },
};
</script>
