import { createRouter, createWebHistory } from 'vue-router';
import auth from '@/lib/auth';
import appRoutes from './routes';
import { trackRouter } from 'vue-gtag-next';
import EventBus from '@/layouts/slots/event-bus';
import { rg4js } from "./lib/raygun";
import { getAppStore } from './store';
let previousRoute = null;

const routes = [
    ...appRoutes,
    {
        path: '/',
        name: 'dashboard',
        component: () => import('./pages/Dashboard.vue'),
        meta: {
            layout: 'default',
        },
    },
    {
        path: '/welcome',
        name: 'landing',
        component: () => import('./pages/Landing.vue'),
        meta: {
            layout: 'default',
        },
    },
    {
        path: '/organization-redirect',
        name: 'organization-redirect',
        meta: { layout: 'empty', global: true },
        component: () => import('./pages/auth/OrganizationRedirect.vue'),
    },
    {
        path: '/organization-error',
        name: 'organization-error',
        meta: { layout: 'auth', global: true },
        component: () => import('./pages/auth/OrganizationError.vue'),
    },
    {
        path: '/organization-new',
        name: 'organization-new',
        meta: { layout: 'empty' },
        component: () => import('./pages/organization/New.vue'),
    },
    {
        path: '/unauthorized',
        name: 'unauthorized',
        meta: { layout: 'default' },
        component: () => import('./pages/Unauthorized.vue'),
    },
    {
        path: '/:catchAll(.*)',
        name: 'notfound',
        meta: { layout: 'empty' },
        component: () => import('./pages/NotFound.vue'),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(function(to, from, next) {
    previousRoute = from;
    rg4js('trackEvent', {
        type: 'pageView',
        path: to.path,
    });
    
    EventBus.emit('page-loaded');
    window.scrollTo(0, 0);
    if (to.meta.auth !== false) {
        // if not, redirect to login page.
        const query = {};
        // do not redirect to unauthorized page on login
        if (to.fullPath !== '/unauthorized') {
            query.redirect = to.fullPath;
        }
        if (!auth.isLoggedIn()) {
            next({
                name: 'auth/login',
                query: query
            });
        } 
    } 


    // organization route
    if(to.meta.global !== true && to.meta.auth !== false){ 
        const appStore = getAppStore();
        // if the org param does not match cache, then reroute.
        const orgId = appStore.organization?._id;

        if(orgId !== to.query.org && to.query.org && to.name !== "organization-redirect") {
            // redirect user.
            next({
                name: 'organization-redirect',
                query: { to: to.query.org }
            });
            return;
        } else if(!to.query.org) {
            const query = to.query;
            query.org = orgId;
            if(query.org) {
                next({ path: to.path, query });
            } else {
                next({
                    name: 'organization-redirect'
                });
            }
           
            return;
        }
    } 

    next();
});

// google analytics tracking.
trackRouter(router);

export default router;
export {previousRoute};